import mixpanel from 'mixpanel-browser';

export class MixpanelTracking {
  private static _instance: MixpanelTracking;

  public static getInstance(): MixpanelTracking {
    if (MixpanelTracking._instance == null) {
      return (MixpanelTracking._instance = new MixpanelTracking());
    }
    return this._instance;
  }

  public constructor() {
    if (MixpanelTracking._instance) {
      throw new Error('Error: Instance creation of MixPanelTracking is not allowed. Use Mixpanel.getInstance instead.');
    }
    mixpanel.init(process.env.REACT_APP_MIXPANEL_KEY || '', { debug: false, ignore_dnt: true });
  }

  public track(name: string, data: object = {}) {
    mixpanel.track(name, data);
  }

  public pageViewed(page: string) {
    this.track(`${page} View`);
  }

  public pageExited(page: string) {
    this.track(`${page} page exit`);
  }

  public tabSelected(page: string, tabName: string) {
    this.track(`${page} Tab Select`, {
      tabName,
    });
  }

  public userLogin(user: { email: string; name: string }) {
    mixpanel.identify(user.email);
    mixpanel.track('login', {
      email: user.email,
      name: user.name,
    });

    mixpanel.people.set({
      $email: user.email,
      $name: user.name,
      // 'Logged In': true,
    });
    mixpanel.people.set({
      'last logged In': new Date().toISOString(),
    });
    mixpanel.people.set_once({
      'First logged in': new Date().toISOString(),
    });
    mixpanel.people.increment('Total number of login');
  }

  public userLogout() {
    this.track('Logout');
    mixpanel.reset();
  }

  public searchFilter(searchText: string) {
    mixpanel.track('search Filter', {
      searchText: searchText,
    });
  }
  public register(role: string) {
    mixpanel.register({
      role: role,
    });
  }

  public isUserLoggedIn() {
    if (mixpanel.get_distinct_id()) {
      return true;
    } else {
      return false;
    }
  }

  public updateUserProfile(field: string, value: string | number | boolean) {
    mixpanel.people.set({
      [field]: value,
    });
  }

  public createJob() {
    mixpanel.track('Create Job Start');
    mixpanel.time_event('Create Job End');
    mixpanel.time_event('Cancel Create Job');
  }

  public employeeStatusChange(status: string, userId: string) {
    mixpanel.track('employeeStatus', {
      status,
      userId,
    });
  }

  public linkClicked(linkDesc: String, linkTitle: String) {
    this.track(`${linkDesc} Click`, {
      clickedTitle: linkTitle,
    });
  }

  public filter(filterLocation: string, filterFields: {}) {
    this.track(`${filterLocation} Filter`, {
      ...filterFields,
    });
  }

  public moduleSelected(moduleName: string) {
    this.track(`Select ${moduleName} Module `);
  }

  public totalPageVisitedCount(page: string) {
    mixpanel.people.increment(`${page} visits`);
  }

  public actionPerformed(action: string, data: {}) {
    this.track(action, {
      ...data,
    });
  }
}
