export const DESIGN = '/design';
export const HOME = '/';
export const ACCOUNT_SELECT = '/account-select';
export const ENTERPRISE = '/enterprise';

export const HIRE = `/hire`;
/** Ecma routes */
export const ECMA = `${HIRE}/ecma`;
export const UPLOAD_RESUME = '/upload-resume';
export const JOB_CREATE = `${ECMA}/create`;
export const JOB_CREATE_ID = `${ECMA}/create/:id`;
export const JOB_VIEW_RESUMES = `${ECMA}/job/:jobId`;
export const CANDIDATE_PROFILE = JOB_VIEW_RESUMES + '/candidateProfile/:parsedResumeId';
export const EDIT_CANDIDATE_PROFILE = JOB_VIEW_RESUMES + '/candidateProfile/:parsedResumeId/edit';
/** End Ecma routes */

/** interview-agent routes */
export const INTERVIEW_AGENT = `${HIRE}/interview-agent`;
/** interview-agent routes */

/** Assessment routes */
export const ASSESSMENT = `${HIRE}/assessment`;
export const ASSESSMENT_BUILDER = `${HIRE}/assessment-builder`;
export const REOPEN_ASSESSMENT = `${HIRE}/reopen-assessment`;
export const CREATE_ASSESSMENT = `${HIRE}/assessments/create`;
/** End Assessment routes */

/** Add Employees routes */
export const ADD_EMPLOYEES = `${HIRE}/add-employees`;
export const ADD_EMPLOYEES_MANUALLY = `${HIRE}/add-employees/manually`;
export const ADD_EMPLOYEES_BULK = `${HIRE}/add-employees/bulk`;
/** End add employees routes */

/** Invite candidate routes */
export const INVITE_CANDIDATE = `${HIRE}/invite-candidate`;
export const ADD_CANDIDATE = `${HIRE}/invite-candidate/add`;
export const EDIT_CANDIDATE = `${HIRE}/invite-candidate/edit/:id`;
/** End Invite candidate  routes */

/** Authentication routes */
export const LOGIN = '/login';
export const LOGOUT = '/logout';
export const FORGET_PASSWORD = '/forget_password';
/** End Authentication routes */

/**Manage app route**/
export const MANAGE = '/manage';

export const EMPLOYEE = MANAGE + '/employee';
export const EMPLOYEE_PROFILE = EMPLOYEE + '/:employeeId';

export const CLIENT = MANAGE + '/client';
export const CLIENT_DETAIL = CLIENT + '/:clientId';
export const SQUAD_OVERVIEW = CLIENT + '/:clientId/overview/:squadId';
export const getClientDetailRoute = (clientId: string) => CLIENT + `/${clientId}`;

export const SQUAD = MANAGE + '/squad';
export const SQUAD_DETAIL = SQUAD + '/:squadId';
export const REQUEST_TO_HIRE = MANAGE + '/requestToHire/:squadId/:resourceId/:squadResourceId';
export const OFFBOARD_AND_REPLACE =
  MANAGE + '/offBoardAndReplace/:squadId/:squadRoleId/:userSquadHistoryId/:involvementHistoryId';
export const OFFBOARD_AND_MOVE = MANAGE + '/offBoardAndMove/:historyId/:involvementHistoryId';

/** End Manage routes */

/**Client POV route**/
export const FM_CLIENT = '/fm-client';
export const SQUAD_LIST = `${FM_CLIENT}/squad-list`;
export const EMPLOYEE_LIST = `${FM_CLIENT}/employee-list`;

export const CLIENT_SQUAD_DETAIL = SQUAD_LIST + '/overview/:squadId';

/** End Client POV routes */

/**Tech View route**/
export const SQUAD_MANAGEMENT = MANAGE + '/squadManagement';

export const VIEW_WORKLOG = `${SQUAD_MANAGEMENT}/squad-overview/:squadId/:tabName/details/:employeeId`;
export const SQUAD_OVERVIEW_TECH = `${SQUAD_MANAGEMENT}/squad-overview/:squadId/:tabName/:clientId?`;
export const RESOURCES = `${SQUAD_MANAGEMENT}/squad-overview/:squadId/requiredResources/:resourceId/:squadResourceId`;
export const ASSIGNED_RESOURCES = `${SQUAD_MANAGEMENT}/squad-overview/:squadId/requiredResources/assigned`;

export const CREATE_INTERNAL_SQUAD = `${SQUAD_MANAGEMENT}/squad-overview/create`;
export const ADD_ROLES_IN_INTERNAL_SQUAD = `${SQUAD_MANAGEMENT}/squad-overview/addroles/:squadId/:resourceId?/:squadResourceId?`;
export const GENERATE_INVOICE = `${SQUAD_MANAGEMENT}/worklog/:squadId/generateInvoice`;

/** End Tech View routes */

/**Analytics Dashboard route**/
export const ANALYTICS_DASHBOARD = MANAGE + '/analytics';

/** End Analytics Dashboard routes */

/**Not found route */
export const NO_PERMISSION = '/403';
