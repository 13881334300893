const {
  REACT_APP_BASE_URL,
  REACT_APP_MANAGE_BASE_URL,
  REACT_APP_COGNITO_PROJECT_REGION,
  REACT_APP_COGNITO_REGION,
  REACT_APP_COGNITO_POOL_ID,
  REACT_APP_COGNITO_CLIENT_ID,
  REACT_APP_COGNITO_DOMAIN,
  REACT_APP_COGNITO_IDENTITY_POOL_ID,
  REACT_APP_ONLINE_TEST_URL,
  REACT_APP_ONLINE_TEST_VERSION,
  REACT_APP_FIREBASE_API_KEY,
  REACT_APP_FIREBASE_PROJECT_ID,
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  REACT_APP_FIREBASE_APP_ID,
  REACT_APP_FIREBASE_AUTH_DOMAIN,
  REACT_APP_VAPID_KEY,
  REACT_APP_ZENDESK_KEY,
  // REACT_APP_WEB_SOCKET_URL,
  REACT_APP_INTERVIEW_AGENT_BASE_URL,
  REACT_APP_JWT_SECRET_KEY,
} = process.env;

const config = {
  baseUrl: REACT_APP_BASE_URL,
  manageAppBaseUrl: REACT_APP_MANAGE_BASE_URL,
  cognitoProjectRegion: REACT_APP_COGNITO_PROJECT_REGION,
  cognitoRegion: REACT_APP_COGNITO_REGION,
  cognitoPoolId: REACT_APP_COGNITO_POOL_ID,
  cognitoClientId: REACT_APP_COGNITO_CLIENT_ID,
  cognitoDomain: REACT_APP_COGNITO_DOMAIN,
  cognitoIdentityPoolId: REACT_APP_COGNITO_IDENTITY_POOL_ID,
  onlineTestUrl: `${REACT_APP_ONLINE_TEST_URL}/api/${REACT_APP_ONLINE_TEST_VERSION}`,
  firebase: {
    apiKey: REACT_APP_FIREBASE_API_KEY,
    authDomain: REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: REACT_APP_FIREBASE_PROJECT_ID,
    messagingSenderId: REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: REACT_APP_FIREBASE_APP_ID,
  },
  firebaseVapidKey: REACT_APP_VAPID_KEY,
  zendeskKey: REACT_APP_ZENDESK_KEY,
  // webSocketUrl: REACT_APP_WEB_SOCKET_URL,
  interviewAgentBaseUrl: REACT_APP_INTERVIEW_AGENT_BASE_URL,
  secretKey: REACT_APP_JWT_SECRET_KEY,
};

export default config;
